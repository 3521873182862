import React from 'react'
import styled, { css } from 'styled-components'

// Elements
import ParseContent from 'components/shared/ParseContent'
import upload from 'img/upload.svg'

// Images
import Linkedin from 'img/linkedin.inline.svg'
import Twitter from 'img/twitter.inline.svg'
import Facebook from 'img/facebook.inline.svg'
import Whatsapp from 'img/whatsapp.inline.svg'
import Phone from 'img/phone.inline.svg'
import Mail from 'img/mail.inline.svg'
import Route from 'img/route.inline.svg'
import arrow from 'img/arrow.svg'
import altarrow from 'img/altarrow.svg'
import LeftChip from 'img/chipleft.inline.svg'
import RightChip from 'img/chipright.inline.svg'

// Components
import FormDuuf from 'components/related/Form'

// Hooks
import useLayout from 'hooks/useLayout'

const StyledFormDuuf = styled(FormDuuf)`
  position: relative;

  & .form-duuf-group {
    margin-bottom: 2rem;
    max-width: unset;

    & a {
      color: ${({ theme }) => theme.color.text.dark};
    }

    @media screen and (max-width: 575px) {
      margin-bottom: 1rem;
    }
    
  }

  & .form-duuf-label {
    font-weight: ${({ theme }) => theme.font.weight.bold};
    font-family: ${({ theme }) => theme.font.family.secondary};
    color: ${({ theme }) => theme.color.text.primaryLight};
    font-size: 16px;
  }

  & .form-duuf-textarea,
  & .form-duuf-input,
  & .form-duuf-select__control {
    width: 100%;
    border-color: ${({ theme }) => theme.color.greyDark};
    border-style: solid;
    border-width: 1px;
    min-height: 39px;
    padding: 0 0 0 10px;
    font-size: 16px;
    background-color: ${({ theme }) => theme.color.light};
    color: ${({ theme }) => theme.color.text.dark};

    &::placeholder {
      color: ${({ theme }) => theme.color.greyDark};
    }
  }

  & .form-duuf-textarea {
    padding: 5px 10px;
    min-height: 135px;
  }

  & .form-duuf-textarea {
    height: 100px;
  }

  & .form-duuf-file-button {
    width: 100%;
    border: 1px solid ${({ theme }) => theme.color.greyDark}};
    position: relative;

    & button {
      position: relative;
      background-color: ${({ theme }) => theme.color.light};
      height: 100%;
      min-height: 39px;
      width: 100%;

      &::before {
        content: 'Select file';
        position: absolute;
        font-family: ${({ theme }) => theme.font.family.secondary};
        top: 9px;
        left: 12px;
        font-size: 14px;
        color: ${({ theme }) => theme.color.greyDark};
      }

      &::after {
        content: '';
        background-image: url(${upload});
        background-size: contain;
        background-repeat: no-repeat;
        height: 27px;
        width: 27px;
        position: absolute;
        right: 5px;
        top: 5px;
      }
    }
  }

  & .form-duuf-file-name {
    margin-left: 0.5rem;
    color: ${({ theme }) => theme.color.text.dark} !important;
  }

  & .form-duuf-checkbox {
    display: flex;
    align-self: flex-start;
    margin-top: 5px;
  }

  & .form-duuf-checkbox-label {
    margin-left: 10px;
    margin-bottom: 0;
    font-family: ${({ theme }) => theme.font.family.secondary};
    font-weight: ${({ theme }) => theme.font.weight.light};
    color: ${({ theme }) => theme.color.text.dark} !important;
    font-size: 16px;
  }

  & .form-duuf-group-consent {
    a.form-duuf-checkbox-label {
      color: ${({ theme }) => theme.color.text.dark};
    }

    & span {
      margin-left: 7px;
      color: ${({ theme }) => theme.color.text.dark};
    }
  }

  & .form-duuf-label-mandatory {
    color: ${({ theme }) => theme.color.secondary};
  }

  & .form-duuf-select__single-value {
    color: ${({ theme }) => theme.color.text.dark} !important;
  }

  & .form-duuf-error {
    color: tomato;
  }

  & .form-duuf-group-submit {
    display: flex;
    justify-content: flex-end;
  }

  & .form-duuf-submit {
    position: relative;

    & button {
      &::before {
      content: unset !important;
    }

    &::after {
      content: unset !important;
    }
    }


    a,
    button {
      font-family: ${({ theme }) => theme.font.family.secondary};
      line-height: 1;
      margin: 0;
      font-size: 14px;
      border: 1px solid ${({ theme }) => theme.color.secondary};
      padding: 12px 50px 12px 20px;
      transition: all 0.3s ease;
      background-color: ${({ theme }) => theme.color.secondary};
      font-weight: ${({ theme }) => theme.font.weight.bold};
      color: ${({ theme }) => theme.color.primaryLight};
    }

    svg {
      color: ${({ theme }) => theme.color.dark};
      transition: all 0.3s ease;
    }

    &:hover {
      &::before {
        background-image: url(${altarrow});
      }

      & a,
      button {
        color: ${({ theme }) => theme.color.secondary};
        background-color: ${({ theme }) => theme.color.primaryLight};
        border-color: ${({ theme }) => theme.color.primaryLight};
      }
    }

    &::before {
      content: '';
      background-image: url(${arrow});
      background-size: cover;
      background-repeat: no-repeat;
      height: 16px;
      width: 19px;
      position: absolute;
      right: 20px;
      top: 0;
      bottom: 0;
      margin-top: auto;
      margin-bottom: auto;
    }

    &-disabled {
      opacity: 0.5;
    }
  }

  & .form-duuf-confirm {
    color: ${({ theme }) => theme.color.text.primaryLight};
    font-size: 20px;

    & b,
    strong {
      color: ${({ theme }) => theme.color.text.secondary};
    }
  }

  & .form-duuf-something-wrong {
    color: red;
  }

  & .form-duuf-loading {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: rgba(255, 255, 255, 0.5);
  }

  .form-duuf-checkbox-consent:hover {
    text-decoration: underline;
  }

`

interface FormContactProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Form
}

const Block = styled.div`
  background-color: ${({ theme }) => theme.color.light};
  transition: all 0.3s ease;
  border-radius: 45px;
  border: 3px solid ${({ theme }) => theme.color.secondary};
  padding: 50px 80px;
  margin-top: -150px;
  min-height: 700px;
  z-index: 5;
  position: relative;

  @media screen and (max-width: 1024px) {
    padding: 40px 40px 20px 40px;
    border-radius: 40px;
  }

  @media screen and (max-width: 575px) {
    padding: 20px 20px 15px 20px;
    border-radius: 20px;
  }
`

const Address = styled.div`
  position: relative;
  padding-left: 12px;

  &::before {
    content: '>';
    position: absolute;
    left: 0;
    font-weight: ${({ theme }) => theme.font.weight.bold};
    font-family: ${({ theme }) => theme.font.family.secondary};
    color: ${({ theme }) => theme.color.text.secondary};
    font-size: 16px;
  }

  & p {
    font-size: 16px;
    line-height: 25px;
    color: ${({ theme }) => theme.color.text.light};
  }
`

const ContactLink = styled.div`
  position: relative;
  padding-left: 12px;

  /* &::before {
    content: '>';
    position: absolute;
    left: 0;
    font-weight: ${({ theme }) => theme.font.weight.bold};
    font-family: ${({ theme }) => theme.font.family.secondary};
    color: ${({ theme }) => theme.color.text.secondary};
    font-size: 16px;
  } */

  & svg {
    width: 20px;
    height: 20px;
    margin-right: 7px;
  }

  & .phone {
    width: 15px;
    height: 15px;
    & path {
      fill: ${({ theme }) => theme.color.secondary};
    }
  }

  & a {
    font-size: 16px;
    line-height: 25px;
    font-weight: ${({ theme }) => theme.font.weight.light};
    font-family: ${({ theme }) => theme.font.family.secondary};
    color: ${({ theme }) => theme.color.text.primaryLight};
  }

  &:hover {
    text-decoration: underline;
  }
`

const Info = styled.div`
  & h2 {
    font-family: ${({ theme }) => theme.font.family.secondary};

    @media screen and (min-width: 992px) {
      font-size: 16px;
      line-height: 16px;
    }
  }
`

const StyledFormContact = styled.section`
  background-color: ${({ theme }) => theme.color.light};
  transition: all 0.3s ease;
  padding-bottom: 150px;

  & .form-duuf-group-consent {
    & a {
      text-decoration: underline;
      color: ${({ theme }) => theme.color.secondary};
    }
  }

  @media screen and (max-width: 991px) {
    padding-bottom: 50px;
  }

  @media screen and (max-width: 575px) {
    padding-bottom: 15px;
  }
`

const LeftChipWrapper = styled.div<{ darkmode: boolean }>`
  position: absolute;
  right: 89%;
  top: -100px;
  z-index: 1;

  & svg {
    & path {
      ${({ darkmode }) =>
        darkmode
          ? css`
              fill: white;
              fill-opacity: 0.22;
            `
          : css`
              fill: black;
              fill-opacity: 0.1;
            `}
    }
  }

  @media screen and (max-width: 1024px) {
    right: 600px;
  }

  @media screen and (max-width: 991px) {
    display: none;
  }
`

const RightChipWrapper = styled.div<{ darkmode: boolean }>`
  position: absolute;
  left: 85%;
  top: -80px;
  z-index: 1;

  & svg {
    & path {
      ${({ darkmode }) =>
        darkmode
          ? css`
              fill: white;
              fill-opacity: 0.22;
            `
          : css`
              fill: black;
              fill-opacity: 0.1;
            `}
    }
  }

  @media screen and (max-width: 1024px) {
    left: 580px;
  }

  @media screen and (max-width: 991px) {
    display: none;
  }
`

const SocialWrapper = styled.div`
  max-width: 200px;
`

const RouteWrapper = styled.div`
  right: 35px;
  @media (min-width: 576px) {
    top: 55px;
  }
  @media (max-width: 575px) {
    top: 35px;
  }
`

const Content = styled(ParseContent)`
  & ul {
    list-style: none;
    padding-left: 20px;
    & li {
      position: relative;
      &:before {
        position: absolute;
        top: 0;
        left: -15px;
        width: 20px;
        height: 20px;
        content: '>';
        font-weight: ${({ theme }) => theme.font.weight.bold};
        font-family: ${({ theme }) => theme.font.family.secondary};
        color: ${({ theme }) => theme.color.text.secondary};
        font-size: 16px;
      }
    }
  }
`

const FormContact: React.FC<FormContactProps> = ({ fields }) => {
  const layout = useLayout()

  return (
    <StyledFormContact>
      <div className="container py-lg-5">
        <div className="row justify-content-center">
          <div className="col-lg-9 position-relative">
            <LeftChipWrapper darkmode={layout.darkMode}>
              <LeftChip />
            </LeftChipWrapper>
            <RightChipWrapper darkmode={layout.darkMode}>
              <RightChip />
            </RightChipWrapper>
            <Block>
              <div className="row justify-content-between">
                <div className="col-lg-6">
                  <StyledFormDuuf
                    id={layout.locale === 'nl_NL' ? 1 : 6}
                    generate
                    privacyUrl="/privacy"
                    privacyUrlPlacement={
                      layout.locale === 'nl_NL'
                        ? 'privacyvoorwaarden'
                        : 'Privacy Policy'
                    }
                  />
                </div>
                <Info className="col-xl-4 col-lg-5">
                  <ParseContent content={fields.description || ''} />
                  <Address className="position-relative pt-lg-5 pt-4">
                    <ParseContent content={fields.address || ''} />
                    <RouteWrapper className="position-absolute">
                      <a
                        href={fields?.maps?.url}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <Route />
                      </a>
                    </RouteWrapper>
                  </Address>
                  <div className="pt-4 mt-lg-3">
                    <ContactLink>
                      <a href={fields.phone?.url || '/'}>
                        <Phone className="phone" />
                        {fields.phone?.title}
                      </a>
                    </ContactLink>
                    <ContactLink>
                      <a href={fields.mail?.url || '/'}>
                        <Mail />
                        {fields.mail?.title}
                      </a>
                    </ContactLink>
                    <ContactLink>
                      <a href={fields.whatsapp?.url || '/'}>
                        <Whatsapp />
                        {fields.whatsapp?.title}
                      </a>
                    </ContactLink>
                  </div>
                  <SocialWrapper className="pt-5 d-flex justify-content-between pe-lg-5 me-lg-3">
                    <div>
                      <a
                        target="_blank"
                        href={fields.linkedin?.url}
                        rel="noreferrer"
                      >
                        <Linkedin />
                      </a>
                    </div>
                    <div>
                      <a
                        target="_blank"
                        href={fields.twitter?.url}
                        rel="noreferrer"
                      >
                        <Twitter />
                      </a>
                    </div>
                    <div>
                      <a
                        target="_blank"
                        href={fields.facebook?.url}
                        rel="noreferrer"
                      >
                        <Facebook />
                      </a>
                    </div>
                  </SocialWrapper>
                  <div className="mt-5">
                    <Content content={fields.openinghours} />
                  </div>
                </Info>
              </div>
            </Block>
          </div>
        </div>
      </div>
    </StyledFormContact>
  )
}

export default FormContact
