import React from 'react'
import styled from 'styled-components'

// Elements
import ParseContent from 'components/shared/ParseContent'
import upload from 'img/upload.svg'

// Hooks
import useLayout from 'hooks/useLayout'

// Images
import arrow from 'img/arrow.svg'
import altarrow from 'img/altarrow.svg'

// Components
import FormDuuf, {
  FormDuufGroup,
  FormDuufSubmit,
} from 'components/related/Form'

const StyledFormDuuf = styled(FormDuuf)`
  position: relative;

  & .form-duuf-group {
    margin-bottom: 2rem;
    max-width: 400px;

    @media screen and (max-width: 991px) {
      max-width: unset;
      margin-bottom: 1rem;
    }
  }

  & .form-duuf-label {
    font-weight: ${({ theme }) => theme.font.weight.bold};
    font-family: ${({ theme }) => theme.font.family.secondary};
    color: ${({ theme }) => theme.color.text.primaryLight};
  }

  & .form-duuf-textarea,
  & .form-duuf-input {
    width: 100%;
    border-color: ${({ theme }) => theme.color.greyDark};
    border-style: solid;
    border-width: 1px;
    min-height: 39px;
    padding: 0 0 0 10px;
    font-size: 16px;
    background-color: ${({ theme }) => theme.color.light};
    color: ${({ theme }) => theme.color.text.dark};

    &::placeholder {
      color: ${({ theme }) => theme.color.greyDark};
    }
  }

  & .form-duuf-textarea {
    padding: 5px 10px;
    min-height: 135px;
    margin-bottom: -0.55rem !important;
  }

  & .form-duuf-textarea {
    height: 100px;
  }

  & .form-duuf-file-button {
    width: 100%;

    & button {
      position: relative;
      background-color: ${({ theme }) => theme.color.light};
      height: 100%;
      min-height: 39px;
      width: 100%;

      &::before {
        content: 'Select file';
        position: absolute;
        font-family: ${({ theme }) => theme.font.family.secondary};
        top: 9px;
        left: 12px;
        font-size: 14px;
        color: ${({ theme }) => theme.color.greyDark};
      }

      &::after {
        content: '';
        background-image: url(${upload});
        background-size: contain;
        background-repeat: no-repeat;
        height: 27px;
        width: 27px;
        position: absolute;
        right: 5px;
        top: 5px;
      }
    }
  }

  & .form-duuf-file-name {
    margin-left: 0.5rem;
  }

  & .form-duuf-checkbox {
    display: flex;
    align-self: flex-start;
    margin-top: 5px;
  }

  & .form-duuf-checkbox-label {
    margin-left: 10px;
    margin-bottom: 0;
    font-family: ${({ theme }) => theme.font.family.secondary};
    font-weight: ${({ theme }) => theme.font.weight.light};
    color: ${({ theme }) => theme.color.dark};
  }

  & .form-duuf-label-mandatory {
    color: ${({ theme }) => theme.color.secondary};
  }

  & .form-duuf-error {
    color: tomato;
  }

  & .form-duuf-group-submit {
    display: flex;
    justify-content: flex-end;
  }

  & .form-duuf-group-checkbox {
    position: relative;
    padding-top: 1.5rem;

    & .form-duuf-label {
      width: 100%;
      border-color: ${({ theme }) => theme.color.greyDark};
      border-style: solid;
      border-width: 1px;
      min-height: 39px;
      padding: 6px 0 0 10px;
      font-size: 16px;
      background-color: ${({ theme }) => theme.color.light};
      color: ${({ theme }) => theme.color.text.primaryLight};
      &:hover {
        cursor: pointer;
      }

      &:after {
        display: inline-block;
        content: url(${arrow});
        margin-left: 14.65rem;
        transform: rotate(90deg) !important;
        width: 24px;
      }
    }

    & > div {
      display: none;
      position: absolute;
      width: 100%;
      top: 62px;
      left: 0;
      border-color: ${({ theme }) => theme.color.greyDark};
      border-style: solid;
      border-width: 1px;
      background-color: ${({ theme }) => theme.color.light};
      padding: 2rem;
      z-index: 1;
    }
  }

  & .form-duuf-group-select {
    & > div > div {
      background: ${({ theme }) => theme.color.light};
    }
    color: ${({ theme }) => theme.color.dark};
  }

  & .form-duuf-submit {
    position: relative;

    a,
    button {
      font-family: ${({ theme }) => theme.font.family.secondary};
      line-height: 1;
      margin: 0;
      font-size: 14px;
      border: 1px solid ${({ theme }) => theme.color.secondary};
      padding: 12px 50px 12px 20px;
      transition: all 0.3s ease;
      background-color: ${({ theme }) => theme.color.secondary};
      font-weight: ${({ theme }) => theme.font.weight.bold};
      color: ${({ theme }) => theme.color.primaryLight};
    }

    svg {
      color: ${({ theme }) => theme.color.dark};
      transition: all 0.3s ease;
    }

    &:hover {
      &::before {
        background-image: url(${altarrow});
      }

      & a,
      button {
        color: ${({ theme }) => theme.color.secondary};
        background-color: ${({ theme }) => theme.color.primaryLight};
        border-color: ${({ theme }) => theme.color.primaryLight};
      }
    }

    &::before {
      content: '';
      background-image: url(${arrow});
      background-size: cover;
      background-repeat: no-repeat;
      height: 16px;
      width: 19px;
      position: absolute;
      right: 20px;
      top: 0;
      bottom: 0;
      margin-top: auto;
      margin-bottom: auto;
    }

    &-disabled {
      opacity: 0.5;
    }
  }

  & .form-duuf-confirm {
    color: ${({ theme }) => theme.color.text.primaryLight};
    font-size: 24px;
    text-align: center;
    padding: 60px 0 100px 0;

    & b,
    strong {
      color: ${({ theme }) => theme.color.text.secondary};
    }
  }

  & .form-duuf-something-wrong {
    color: red;
  }

  & .form-duuf-loading {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: rgba(255, 255, 255, 0.5);
  }

  & .form-duuf-group-consent {
    color: ${({ theme }) => theme.color.dark};
    & a {
      color: ${({ theme }) => theme.color.secondary};
      text-decoration: underline;
    }

    & input {
      margin-right: 10px;
    }
  }
`
interface FormSignupInternProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Form
}

const StyledFormSignupIntern = styled.section`
  background-color: ${({ theme }) => theme.color.grey};
  padding: 50px 0 30px 0;

  @media screen and (max-width: 991px) {
    padding: 15px 0;
  }
`

const Content = styled.div`
  & h2 {
    @media screen and (min-width: 992px) {
      font-size: 75px;
      line-height: 70px;
    }
  }

  & p {
    font-weight: ${({ theme }) => theme.font.weight.bold};
  }
`

const FormSignupIntern: React.FC<FormSignupInternProps> = ({ fields }) => {
  const layout = useLayout()

  const [clickedRegions, setClickedRegions] = React.useState(false)

  const regions = document.getElementsByClassName(
    'form-duuf-checkboxes-input_17'
  )

  if (regions[0]) {
    regions[0].addEventListener('mouseover', () => setClickedRegions(true))
    regions[0].addEventListener('mouseleave', () => setClickedRegions(false))

    if (clickedRegions) {
      regions[0].children[1].classList.add('d-block')
    } else {
      regions[0].children[1].classList.remove('d-block')
    }
  }

  return (
    <StyledFormSignupIntern>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-11">
            <Content className="row">
              <div className="col-lg-5">
                <ParseContent content={fields.title || ''} />
              </div>
              <div className="col-lg-7 pt-2">
                <ParseContent content={fields.description || ''} />
              </div>
            </Content>
          </div>
          <div className="col-lg-10 pt-lg-5 pt-3 position-relative">
            <StyledFormDuuf
              id={layout.locale === 'nl_NL' ? 12 : 11}
              generate={false}
              privacyUrl={
                layout.locale === 'nl_NL' ? '/nl/privacyverklaring' : '/privacy'
              }
              privacyUrlPlacement={
                layout.locale === 'nl_NL'
                  ? 'privacystatement'
                  : 'Privacy Policy'
              }
            >
              <div className="row">
                <div className="col-lg-4 col-sm-6">
                  <FormDuufGroup showIds={[1, 2, 3, 13]} />
                </div>
                <div className="col-lg-4 col-sm-6">
                  <FormDuufGroup showIds={[6, 11, 12, 14]} />
                </div>
                <div className="col-lg-4">
                  <FormDuufGroup showIds={[17, 10, 15]} />
                </div>
                <div className="col-lg-12">
                  <FormDuufGroup showIds={[16]} />
                </div>
              </div>
              <div className="d-flex justify-content-center">
                <FormDuufSubmit />
              </div>
            </StyledFormDuuf>
          </div>
        </div>
      </div>
    </StyledFormSignupIntern>
  )
}

export default FormSignupIntern
